<template>
  <div class="aprovacao-item">
    <div v-if="arrematante.image && arrematante.image.thumb" class="u-img-rounded user-image">
      <img :src="arrematante.image.thumb.url" />
    </div>
    <div class="font-bold">{{arrematante.pessoa.name}}</div>
    <div class="font-12 m-t-xs">{{arrematante.pessoa.document}}</div>
    <div class="font-12 m-t-xs">{{arrematante.apelido}}</div>
    <div class="font-12 text-blue-grey-6 m-t-xxs">{{arrematante.email}}</div>
    <div class="font-11 text-blue-grey-6 m-t">Para análise desde {{arrematante.analise.data.date|formatDate}}</div>
    <div class="font-11 text-blue-grey-6 m-t-xs u-alert-default" v-if="arrematante.analise && arrematante.analise.user">
      Sendo analisado por <strong>{{arrematante.analise.user.username}}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AprovacaoItem',
  props: ['arrematante']
}
</script>
