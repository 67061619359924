<template>
  <menu-lateral :auto-folded="false" default-class="menu-lateral-leilao" no-folded posmenu-full> <!-- TODO: Mudar nome de classe -->
    <div slot="icon-toggle"></div>
    <div class="">
      <h3>Arrematantes</h3>
      <h1>Aprovação de Cadastros</h1>

      <!--<div :class="`labels m-t flex justify-between font-bold ${Status[status.status].color}`" v-for="status in statuses">
        <div>{{Status[status.status].title}}</div>
        <div>{{status.quantidade}}</div>
      </div>-->

      <div class="labels m-t-lg flex justify-between font-bold">
        <div>Para análise</div>
        <div>{{arrayStatus[arrematanteStatus.STATUS_EM_ANALISE] || 0}}</div>
      </div>

      <div class="labels m-t flex justify-between text-blue-grey-6">
        <div>Sem documentação</div>
        <div>{{arrayStatus[arrematanteStatus.STATUS_PENDENTE] || 0}}</div>
      </div>

      <div class="labels m-t flex justify-between text-positive">
        <div>Aprovados</div>
        <div>{{arrayStatus[arrematanteStatus.STATUS_APROVADO] || 0}}</div>
      </div>

      <div class="labels m-t flex justify-between text-red-5">
        <div>Reprovados</div>
        <div>{{arrayStatus[arrematanteStatus.STATUS_REPROVADO] || 0}}</div>
      </div>

      <div class="labels m-t flex justify-between text-purple">
        <div>Bloqueados</div>
        <div>{{arrayStatus[arrematanteStatus.STATUS_BLOQUEADO] || 0}}</div>
      </div>

      <div class="labels m-t flex justify-between text-grey-7">
        <div>Total</div>
        <div>{{total}}</div>
      </div>

      <div class="m-t-lg">
        <slot />
      </div>

      <!--<div class="m-t-lg">
        <ul class="menu-leilao-items">
          &lt;!&ndash;<li>
            <span>Teste</span>
          </li>&ndash;&gt;
          <li>
            <a class="flex">
              &lt;!&ndash;<div class="erp-icon-size"><i class="erp-icon times"></i></div>&ndash;&gt;
              <u-icon name="times" type="fa"  />
              <span class="menu-lbl">Teste</span>
            </a>
          </li>
        </ul>
      </div>-->
    </div>
  </menu-lateral>
</template>

<script>
import MenuLateral from '../../../layout/MenuLateral'
import {format} from "date-fns"
// import {list} from '../../../../domain/arrematante/services/arrematante-status-cache'
import {STATUS_APROVADO, STATUS_BLOQUEADO, STATUS_EM_ANALISE, STATUS_PENDENTE, STATUS_REPROVADO} from '@/domain/arrematante/helpers/Status'
import {getCountStatusCadastro} from '@/domain/arrematante/services'

export default {
  name: 'MenuAprovacao',
  components: {MenuLateral},
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  data () {
    return {
      busca: '',
      statuses: [],
      arrematanteStatus: {
        STATUS_APROVADO,
        STATUS_BLOQUEADO,
        STATUS_EM_ANALISE,
        STATUS_PENDENTE,
        STATUS_REPROVADO
      }
    }
  },
  created () {
    this.countStatusCadastros()
    // this.listStatuses()
  },
  computed: {
    now () {
      return format(new Date(), 'dd/MM/yyyy')
    },
    total () {
      const tmp = [...this.statuses]
      return this.$options.filters.number(tmp.reduce((acc, status) => Number(acc) + Number(status.count), 0))
    },
    arrayStatus () {
      const obj = {...this.arrematanteStatus}
      for (const value of this.statuses) {
        obj[+value.status] = this.$options.filters.number(value.count)
      }
      return obj
    }
  },
  methods: {
    link (m) {
      console.log('Link', m)
      if (m.route) {
        this.$router.push({name: m.route, params: {id: this.leilao.id}})
      }
    },
    /* listStatuses () {
      list(1000, 1, '')
        .then(response => {
          this.statuses = response.data
        })
    }, */
    countStatusCadastros () {
      getCountStatusCadastro()
              .then(response => {
                this.statuses = response.data
              })
    }
  }
}
</script>
